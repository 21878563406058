:root {
    --primary-color:#D9187D;
}

.div-container {
    width: 100%;
    padding: 20px;
    border: 1px solid #DEE2E6;
    border-radius: 10px;
    background: #F8F9FA;
}

.title-h {
    color: #D9187D;
    letter-spacing: 1px;
}

.p-button {
    color: #ffffff;
    background: #D9187D;
    border: 1px solid #D9187D;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
    background: #F31C92;
    color: #ffffff;
    border-color: #F31C92;
}

.p-button:enabled:active,
.p-button:not(button):not(a):not(.p-disabled):active {
    background: #E93B96;
    color: #ffffff;
    border-color: #E93B96 !important;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #FBB3DA;
}

.p-button-cancel {
    color: #ffffff;
    background: #9E9E9E;
    border: 1px solid #9E9E9E;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}

.p-button-cancel:enabled:hover,
.p-button-cancel:not(button-cancel):not(a):not(.p-disabled):hover {
    background: #bdbdbd;
    color: #ffffff;
    border-color: #bdbdbd;
}

.p-button-cancel:enabled:active,
.p-button-cancel:not(button-cancel):not(a):not(.p-disabled):active {
    background: #bdbdbd;
    color: #ffffff;
    border-color: #bdbdbd !important;
}

.p-button-cancel:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bdbdbd;
}

.container-button {
    display: flex;
    justify-content: end;
}

.p-text {
    font-size: 15px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #D9187D;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #F31C92;
}

.p-inputtext {
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
}

.p-inputtext:enabled:hover {
    border-color: #F31C92;
}

.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem #FFF;
    border-color: #F31C92;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #F31C92;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem #FBB3DA;
    border-color: #F31C92;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #F31C92;
}

.p-multiselect, .p-dropdown {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 1px solid #ced4da;
    border-radius: 0px;
}

.p-link:focus {
    box-shadow: 0 0 0 0.2rem #FBB3DA;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: #F31C92;
}

.p-button.p-button-outlined {
    color: #F31C92;
}

.p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    color: #E93B96;
}

.p-button.p-button-text {
    color: #E93B96;
}

.p-button.p-button-text:enabled:hover, .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
    color: #F31C92;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus, .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    box-shadow: 0 0 0 0.2rem #FBB3DA;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: 0 0 0 0.2rem #FBB3DA;
}

.overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    cursor: pointer;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.p-progressbar .p-progressbar-value {
    background: #379E87;
}

.p-progressbar-determinate .p-progressbar-label {
    color: #fff;
}

.custom-zindex {
    z-index: 99999999999999999999 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover  {
    border-color: #F31C92;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):focus-visible  {
    outline: 1px #F31C92;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #F31C92;
    background: #F31C92;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #E93B96;
    background: #E93B96;
}

.p-multiselect:not(.p-disabled):hover, .p-dropdown:not(.p-disabled):hover {
    border-color: #F31C92;
}

.p-multiselect:not(.p-disabled).p-focus, .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem #FFF;
    border-color: #F31C92;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #495057;
}

.p-fluid .p-fileupload .p-button {
    width: 3rem !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #FBB3DA;
}

.d-layout {
    border: 1px solid #dee2e6;
    background: #F8F9FA;
    padding: 20px;
    border-radius: 10px;
}

.timeline-demo .custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.timeline-demo .p-timeline-event-content,
.timeline-demo .p-timeline-event-opposite {
    line-height: 1;
}

@media screen and (max-width: 960px) {
    .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) {
        flex-direction: row !important;
    }
    .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
        text-align: left !important;
    }
    .timeline-demo .customized-timeline .p-timeline-event-opposite {
        flex: 0;
    }
    .timeline-demo .customized-timeline .p-card {
        margin-top: 1rem;
    }
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    color: #50575E;
}

.p-overlaypanel .p-overlaypanel-close {
    background: #F31C92;
}

/* OverlayPanelDemo.css */

.overlaypanel-demo .select-product-button {
    min-width: 15rem;
}

.overlaypanel-demo .product-image {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #E93B96;
}

.label-color-title {
    color:'#595a5d';
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    border: 1px solid #cbd3da;
    background: #dae0e5;
}

.placeholder-select {
    padding: 8px 0;
    color: #6c757d;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 1px solid #ced4da;
    border-radius: 0px;
}

div[data-vimeo-initialized="true"] {
    border-radius: 10px;
}

.custom-badge {
    border-radius: 4px;
    padding: .25rem 1rem;
    color: #fff;
    margin-left: 1rem;
}

.text-color {
    color: #F31C92;
}


/* SkeletonDemo.css */

.custom-skeleton {
    border: 1px solid var(--surface-d);
    border-radius: 4px;
}
.custom-skeleton ul {
    list-style: none;
}

.p-badge {
    background: #F31C92;
    color: #ffffff;
}


.p-card .p-card-footer {
    padding: .5rem 0 0 0;
}

.p-card .p-card-content {
    padding: 0.5rem 0;
}
                 
.text-underline:hover {
    text-decoration: underline !important;
} 

.paragraph {
    white-space: pre;
}

.background-yt {
    border-color: #ff0000;
    color: #ffffff;
    background-color: rgba(255, 0, 0, .2);
    transition: all 0.5s ease;
}

.background-fb {
    border-color: #1877F2;
    background-color: rgba(24, 119, 242, .2);
    transition: all 0.5s ease;
}

.background-ig {
    border-color: #F1049B;
    background-color: rgba(241, 4, 155, .2);
    transition: all 0.5s ease;
}

.background-tw {
    border-color: #1C93E4;
    background-color: rgba(28, 147, 228, .2);
    transition: all 0.5s ease;
}

.background-lk {
    border-color: #0A66C2;
    background-color: rgba(10, 102, 194, .2);
    transition: all 0.5s ease;
}

.background-vm {
    border-color: #00A4E3;
    background-color: rgba(0, 164, 227, .2);
    transition: all 0.5s ease;
}

.background-tk {
    border-color: #ff0050;
    background-color: rgba(255, 0, 80, .2);
    transition: all 0.5s ease;
}

.p-selectbutton .p-button.p-highlight {
    background: #F31C92;
    border-color: #F31C92;
    color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
    background: #E93B96;
    border-color: #E93B96;
    color: #ffffff;
}

.layout-topbar .layout-topbar-logo {
    width: 243px;
}

.layout-topbar .layout-topbar-logo img {
    width: auto;
    height: 65px;
    margin-right: 0.5rem;
}

.row-assigned {
    background-color: #ececee !important;
}